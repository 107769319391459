<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Prayer Request Details</h1>
    </page-header>

    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcPrayerRequestDetails }}</h1>
        <div class="d-flex mb-3">
          <i class="mr-3">
            <iCalendar />
          </i>
          <p>{{ startDate }} - {{ endDate }}</p>
        </div>
        <div class="d-flex">
          <span class="label font-style-2 mr-3">{{ translations.tcStatus }}:</span>
          <div>
            <span :class="isActive ? 'badge badge-info' : 'badge badge-warning'">
              {{status}}
            </span>
          </div>
        </div>
        <div class="d-flex">
          <span class="label font-style-1 mr-2">{{ translations.tcTitle }}:</span>
          <span class="value font-style-1 font-weight-bold">
            {{ prayerRequestItem.cid_request_title }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcPrayerTitle">
              <i-tooltip />
            </span>
          </span>
        </div>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
        </header>
        <div class="body">
          <p v-html="prayerRequestItem.cid_request_detail"></p>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button
              variant="tertiary"
              @click="handleBackClick"
              class="flex-0 w-100-sd mb-sm-0">{{ translations.tcBack }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-request-details',
  mixins: [translationMixin],
  data() {
    return {
      translations: {}
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    handleBackClick() {
      this.$router.push({ name: 'prayer-add-edit-request' })
    },
  },
  async created() {
    this.setLoadingStatus(true)
    if (
      !this.prayerRequestItem ||
      Object.keys(this.prayerRequestItem).length <= 0
    ) {
      //  this.$router.push({ name: 'add-edit-prayer-requests' })
    }

    await this.getViewTranslations()
    .then(() => {
      this.setLoadingStatus(false)
    })
  },
  computed: {
    ...mapGetters({
      prayerRequestItem: 'prayerCenter/getPrayerRequestItem',
      prefCulture: 'user/userPreferredCulture'
    }),
    startDate() {
      let showingDate = new Date(this.prayerRequestItem.cid_start_date)
      return showingDate.toDateString()
    },
    endDate() {
      let showingDate = new Date(this.prayerRequestItem.cid_end_date)
      return showingDate.toDateString()
    },
    status(){
       let date = new Date(this.prayerRequestItem.cid_end_date)
       if (date.getTime() < (new Date()).getTime()){
           return this.translations.tcExpired
       }
       return this.translations.tcActive
    },
    isActive(){
      let date = new Date(this.prayerRequestItem.cid_end_date)
       if (date.getTime() < (new Date()).getTime()){
           return false
       }
       return true
    }
  },
  components: {
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    iTooltip: iTooltip,
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}
</style>
